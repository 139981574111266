import React, { useState, useEffect } from 'react';
import Tree, { TreeNodeDatum, RenderCustomNodeElementFn } from 'react-d3-tree';
import './OKRRelationship.css';
import supabase from '../Auth/supabase';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface TreeNode {
  name: string;
  attributes?: {
    status: string;
    type: string;
  };
  children?: TreeNode[];
  ParentGoalId?: string;
}

function transformOKRs(okrs: OKR[]): TreeNode {
  const activeOKRs = okrs.filter(okr => okr.Status !== 'Completed' && okr.Status !== 'Failed');

  const companyOKRs = activeOKRs.filter(okr => okr.GoalType === 'Company' && okr.Goal);
  const teamOKRs = activeOKRs.filter(okr => okr.GoalType === 'Team');
  const personalOKRs = activeOKRs.filter(okr => okr.GoalType === 'Personal');

  const idMap = companyOKRs.reduce((acc, okr) => {
    acc[okr.Goal] = okr._id;
    return acc;
  }, {} as { [key: string]: string });

  teamOKRs.forEach(okr => {
    okr.ParentGoalId = idMap[okr.ParentGoalId!] || okr.ParentGoalId;
  });

  const structuredTeamOKRs = teamOKRs.map(teamOkr => ({
    name: teamOkr.Goal,
    attributes: { status: teamOkr.Status, type: teamOkr.GoalType },
    children: personalOKRs.filter(pOkr => pOkr.ParentGoalId === teamOkr._id).map(pOkr => ({
      name: pOkr.Goal,
      attributes: { status: pOkr.Status, type: pOkr.GoalType }
    })),
    ParentGoalId: teamOkr.ParentGoalId
  }));

  const structuredCompanyOKRs = companyOKRs.map(companyOkr => {
    const children = structuredTeamOKRs.filter(tOkr => tOkr.ParentGoalId === companyOkr._id);
    return {
      name: companyOkr.Goal,
      attributes: { status: companyOkr.Status, type: companyOkr.GoalType },
      children: children,
      collapsed: true // Collapse team OKRs initially
    };
  });

  return {
    name: "All OKRs",
    children: structuredCompanyOKRs,
    };
}

function OKRRelationships() {
  const [okrs, setOkrs] = useState<TreeNode | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOKRs = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          const response = await fetch(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const data = await response.json();
          const treeData = transformOKRs(data);
          setOkrs(treeData);
        } else {
          console.error("No auth token found");
          setError("No auth token found");
        }
      } catch (error) {
        console.error("Failed to fetch OKRs:", error);
        setError("Failed to fetch OKRs");
      }
    };
  
    fetchOKRs();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  const renderNode: RenderCustomNodeElementFn = ({ nodeDatum, toggleNode }) => {
    const lines = nodeDatum.name.split(' ');
    const lineHeight = 20;
  
    return (
      <g className="tree-node" onClick={toggleNode}>
        <rect 
          x={-180} /* Adjust x to match new width */
          y={-30} 
          width="360" /* Double the width */
          height={lineHeight + 20} 
          fill="white" 
          stroke="#555" 
          strokeWidth="2" 
          rx="8" 
          ry="8"
        />
        <text
          x="0"
          y="0"
          textAnchor="middle" /* Center the text horizontally */
          alignmentBaseline="middle" /* Center the text vertically */
          style={{ fontSize: '14px', fontFamily: 'Arial', wordWrap: 'break-word', whiteSpace: 'normal' }}
        >
          {nodeDatum.name}
        </text>
      </g>
    );
  };
  
    
  return (
    <div style={{ width: '100%', height: '800px' }}>
      {okrs ? (
        <Tree
          data={okrs}
          orientation="vertical"
          pathFunc="straight"
          renderCustomNodeElement={renderNode}
          translate={{ x: 400, y: 50 }}
          separation={{
            siblings: 4,
            nonSiblings: 3
          }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default OKRRelationships;
