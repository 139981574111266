// src/components/MainCards.tsx
import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import StatusPieChart from './PieChart';
import colors from './color';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface MainCardsLayoutProps {
  goalType: string;
  okrs: OKR[];
  selectedTeam: string;
  selectedUser: string;
}

interface OKROverviewCardProps {
  goalType: string;
  okrs: OKR[];
  selectedTeam: string;
}

export function OKROverviewCard({ goalType, okrs, selectedTeam }: OKROverviewCardProps) {
  const nonCompletedOkrs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );
  let title = `${goalType} OKRs`;
  if (goalType === 'Team' && selectedTeam !== 'All Teams') {
    title = `${selectedTeam} Team OKRs`;
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {title}
        </Typography>
        {nonCompletedOkrs.length > 0 ? (
          nonCompletedOkrs.map((okr, index) => (
            <Typography key={index} variant="body1" sx={{ mt: 1 }}>
              {okr.Goal}
            </Typography>
          ))
        ) : (
          <Typography variant="body2" sx={{ mt: 1 }}>
            No OKRs found.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

interface OKRStatusOverviewCardProps {
  okrs: OKR[];
}

function OKRStatusOverviewCard({ okrs }: OKRStatusOverviewCardProps) {
  const nonCompletedOkrs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Status Overview
        </Typography>
        <StatusPieChart okrs={nonCompletedOkrs} />
      </CardContent>
    </Card>
  );
}

export const MainCardsLayout: React.FC<MainCardsLayoutProps> = ({
  goalType,
  okrs,
  selectedTeam,
  selectedUser,
}) => {
  return (
    <Box sx={{ flexGrow: 1, px: 2 }}>
      <Grid container spacing={2}>
        {/* Adjusted Columns */}
        <Grid item xs={12} md={6}>
          <OKROverviewCard
            goalType={goalType}
            okrs={okrs}
            selectedTeam={selectedTeam}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OKRStatusOverviewCard okrs={okrs} />
        </Grid>
      </Grid>
    </Box>
  );
};
