import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import Sidebar from './components/Navbar';
import Dashboard from './Pages/Dashboard';
import OKRRelationships from './Pages/OKRRelationship';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import FocusOKRs from './Pages/FocusOKR';
import MyOKRs from './Pages/MyOKRs'; 
import PreviousOKRs from './Pages/PreviousOKR';
import LoginPage from './LoginPage';
import PrivateRoute from './PrivateRoute';
import SignUp from './Auth/Signup';
import ForgotPassword from './Auth/ForgotPassword';
import PasswordResetSuccess from './Auth/PasswordResetSuccess';
import ResetPassword from './Auth/ResetPassword';
import supabase from './Auth/supabase';
import TopBar from './components/TopBar';
import { Box } from '@mui/material';
import Layout from './Layout'; 


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

function App() {
  const [okrs, setOkrs] = useState<OKR[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [authCompleted, setAuthCompleted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchOKRs = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token) {
        const response = await axios.get(`${apiBaseUrl}/api/okrs`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        });
        const okrsData = Array.isArray(response.data) ? response.data : [];
        setOkrs(okrsData);
      } else {
        console.error('No auth token found');
      }
    } catch (error) {
      console.error('There was an error fetching the OKRs:', error);
    }
  };

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();

      setIsAuthenticated(!!session);
      setAuthCompleted(true);
      if (session) {
        // User is authenticated, fetch OKRs
        await fetchOKRs();
      }
    };

    checkSession();

    // Set up the authentication state change listener
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setIsAuthenticated(!!session);
      setAuthCompleted(true);
      if (session) {
        // User is authenticated, fetch OKRs
        await fetchOKRs();
      } else {
        // User is not authenticated, clear OKRs
        setOkrs([]);
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem('authToken');
    setOkrs([]); // Clear OKRs when logging out
    navigate('/login');
    window.location.reload(); // Reload the page to clear the cached data
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout logout={logout} />}>
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  okrs={okrs}
                />
              }
            />
            <Route path="/my-okrs" element={<MyOKRs />} />
            <Route path="/okr-relationships" element={<OKRRelationships />} />
            <Route path="/focus-okrs" element={<FocusOKRs />} />
            <Route path="/previous-okrs" element={<PreviousOKRs />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}


export default App;
