import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FokusOKRTile from '../components/FokusOKRTiles';
import supabase from '../Auth/supabase';
import { FormControl, InputLabel, Select, MenuItem, Typography, TextField,Button, TextFieldProps } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface Team {
  name: string;
}

interface User {
  name: string;
  assigneeId: string;
}

function FocusOKRs() {
  const [okrs, setOkrs] = useState<OKR[]>([]);
  const [filteredOkrs, setFilteredOkrs] = useState<OKR[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<string>('All');
  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [timeFilterType, setTimeFilterType] = useState<string>('All');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);


  const excludedGoalTypes = ['Personal'];

  useEffect(() => {
    const fetchOKRs = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          const response = await axios.get<OKR[]>(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setOkrs(response.data);
        } else {
          console.error("No auth token found");
          setError("No auth token found");
        }
      } catch (error) {
        console.error("Failed to fetch OKRs:", error);
        setError("Failed to fetch OKRs");
      }
    };

    const fetchTeamsAndUsers = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          // Fetch teams
          const teamsResponse = await axios.get<Team[]>(`${apiBaseUrl}/api/teams`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setTeams(teamsResponse.data);

          // Fetch users
          const usersResponse = await axios.get<User[]>(`${apiBaseUrl}/api/okr-owners`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUsers(usersResponse.data);
        } else {
          console.error('No auth token found');
        }
      } catch (error) {
        console.error('Failed to fetch teams or users:', error);
      }
    };

    fetchOKRs();
    fetchTeamsAndUsers();
  }, []);

  useEffect(() => {
    // Apply filters to OKRs
    let filtered = okrs.filter(
      (okr) => !excludedGoalTypes.includes(okr.GoalType)
    );


    if (filterType === 'Company') {
      filtered = filtered.filter((okr) => okr.GoalType === 'Company');
    } else if (filterType === 'Team') {
      if (selectedTeam) {
        filtered = filtered.filter((okr) => okr.Team === selectedTeam);
      }
    } else if (filterType === 'Individual') {
      if (selectedUser) {
        filtered = filtered.filter(
          (okr) => okr.AssigneeId === selectedUser || okr.AssigneeName === selectedUser
        );
      }
    }
    // Time Period Filter
    if (timeFilterType === 'Custom' && fromDate && toDate) {
      filtered = filtered.filter((okr) => {
        const deadline = new Date(okr.TimePeriod);
        return deadline >= fromDate && deadline <= toDate;
      });
    }

    setFilteredOkrs(filtered);
  }, [okrs, filterType, selectedTeam, selectedUser, timeFilterType, fromDate, toDate]);

  if (error) {
    return <div>{error}</div>;
  }

  // Filter OKRs excluding personal goal types and apply status filter
  const redOkrs = filteredOkrs.filter(
    (okr) => okr.Status === 'Red'
  );
  const amberOkrs = filteredOkrs.filter(
    (okr) => okr.Status === 'Amber'
  );

  return (
    <div style={{ paddingLeft: '250px', paddingRight: '30px', paddingTop: '20px' }}>
      {/* Filter Container */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', flexWrap: 'wrap' }}>
        <Typography variant="h6" style={{ marginRight: '10px' }}>
          Filter by:
        </Typography>
        {/* Type Filter */}
        <FormControl variant="outlined" size="small" style={{ minWidth: 120, marginRight: '10px' }}>
          <InputLabel id="filter-type-label">Type</InputLabel>
          <Select
            labelId="filter-type-label"
            id="filter-type-select"
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value);
              setSelectedTeam('');
              setSelectedUser('');
            }}
            label="Type"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
            <MenuItem value="Team">Team</MenuItem>
            <MenuItem value="Individual">Individual</MenuItem>
          </Select>
        </FormControl>

        {filterType === 'Team' && (
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 150, marginRight: '10px' }}
          >
            <InputLabel id="team-select-label">Select Team</InputLabel>
            <Select
              labelId="team-select-label"
              id="team-select"
              value={selectedTeam}
              onChange={(e) => setSelectedTeam(e.target.value)}
              label="Select Team"
            >
              {teams.map((team, index) => (
                <MenuItem key={index} value={team.name}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {filterType === 'Individual' && (
          <FormControl variant="outlined" size="small" style={{ minWidth: 150, marginRight: '10px' }}>
            <InputLabel id="user-select-label">Select User</InputLabel>
            <Select
              labelId="user-select-label"
              id="user-select"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label="Select User"
            >
              {users.map((user, index) => (
                <MenuItem key={index} value={user.assigneeId}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Time Period Filter */}
        <FormControl variant="outlined" size="small" style={{ minWidth: 150, marginRight: '10px' }}>
          <InputLabel id="time-filter-type-label">Time Period</InputLabel>
          <Select
            labelId="time-filter-type-label"
            id="time-filter-type-select"
            value={timeFilterType}
            onChange={(e) => {
              setTimeFilterType(e.target.value);
              if (e.target.value === 'All') {
                setFromDate(null);
                setToDate(null);
              }
            }}
            label="Time Period"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>

        {timeFilterType === 'Custom' && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
    label="From"
    value={fromDate}
    onChange={(newValue) => setFromDate(newValue)}
    renderInput={(params: TextFieldProps) => (
      <TextField
        {...params}
        variant="outlined"
        size="small"
        style={{ marginRight: '10px' }}
      />
    )}
  />
  <DatePicker
    label="To"
    value={toDate}
    onChange={(newValue) => setToDate(newValue)}
    renderInput={(params: TextFieldProps) => (
      <TextField {...params} variant="outlined" size="small" />
    )}
  />
</LocalizationProvider>


)}

            </div>

      {/* OKRs Display */}
      <div style={{ display: 'flex', gap: '10px' }}>
        <div style={{ flex: 1 }}>
          <h2>Red OKRs</h2>
          {redOkrs.length > 0 ? (
            redOkrs.map((okr) => <FokusOKRTile key={okr._id} okr={okr} />)
          ) : (
            <p>No red OKRs found.</p>
          )}
        </div>
        <div style={{ flex: 1 }}>
          <h2>Amber OKRs</h2>
          {amberOkrs.length > 0 ? (
            amberOkrs.map((okr) => <FokusOKRTile key={okr._id} okr={okr} />)
          ) : (
            <p>No amber OKRs found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FocusOKRs;
