// src/pages/Dashboard.tsx
import React, { useState, useEffect } from 'react';
import TopCards from '../components/TopCards';
import { MainCardsLayout } from '../components/MainCards';
import { Box, ThemeProvider } from '@mui/material';
import theme from '../theme';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface DashboardProps {
  okrs: OKR[];
}

const Dashboard: React.FC<DashboardProps> = ({ okrs }) => {
  const [goalType, setGoalType] = useState<string>('Company');
  const [selectedTeam, setSelectedTeam] = useState<string>('All Teams');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [scopeFilteredOkrs, setScopeFilteredOkrs] = useState<OKR[]>([]);
  const [error, setError] = useState<string | null>(null);

  // Filter OKRs based on the current scope selection (goalType, selectedTeam, selectedUser)
  useEffect(() => {
    let filtered = okrs;

    if (goalType === 'Company') {
      filtered = filtered.filter((okr) => okr.GoalType === 'Company');
    } else if (goalType === 'Team') {
      filtered = filtered.filter((okr) => okr.GoalType === 'Team');
      if (selectedTeam !== 'All Teams') {
        filtered = filtered.filter((okr) => okr.Team === selectedTeam);
      }
    } else if (goalType === 'Individual') {
      if (selectedUser) {
        filtered = filtered.filter(
          (okr) =>
            (okr.AssigneeId === selectedUser || okr.AssigneeName === selectedUser) &&
            okr.GoalType !== 'Personal'
        );
      } else {
        filtered = [];
      }
    }

    setScopeFilteredOkrs(filtered);
  }, [okrs, goalType, selectedTeam, selectedUser]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, p: 1 }}>
        {/* Top Cards (Filters) */}
        <TopCards
          setGoalType={setGoalType}
          selectedGoalType={goalType}
          setSelectedTeam={setSelectedTeam}
          selectedTeam={selectedTeam}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
        />

        {/* Main Cards Layout */}
        <MainCardsLayout
          goalType={goalType}
          okrs={scopeFilteredOkrs}
          selectedTeam={selectedTeam}
          selectedUser={selectedUser}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
